<template>
  <div v-show="showMask" class="maks" @touchmove.prevent @mousewheel.prevent>
    <div class="mk_loading">
      <!--      <img alt="logo" src="../../assets/img/loading.gif">-->
      <div ref="loadingAnimation" class="loading_img"></div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import loadingJson from '@/assets/lottie/loading.json';

export default {
  name: "loading",
  data() {
    return {
      showMask: false,
    };
  },
  created() {},
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.loadingAnimation, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: loadingJson // the path to the animation json
    });
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.maks {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.8);
  .mk_loading {
    height: 280px;
    width: 280px;
    margin: 0 auto;
    .loading_img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>