const { utils } = require("ethers");
const BigNumber = require("bignumber.js");

export function parseAmount(amount, decimal = '18') {
  decimal = parseInt(decimal);
  return utils.parseUnits(toFixed(amount, decimal), decimal).toString();
}

export function formatAmount(amount, decimal = '18') {
  decimal = parseInt(decimal);
  return utils.formatUnits(amount, decimal).toString();
}

export function cleanupAmount(amount) {
  return amount.replace(/,/g, '').trim();
}

export function trimLeadingZeroes(value) {
  value = value.replace(/^0+/, '');
  if (value === '') {
    return '0';
  }
  return value;
}

export function trimTrailingZeroes(value) {
  return value.replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
}

export function formatWithCommas(value) {
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(value)) {
    value = value.toString().replace(pattern, '$1,$2');
  }
  return value;
}

export function toFixed(number, pp) {
  let num = isNaN(number) || !number ? 0 : number;
  let p = isNaN(pp) || !pp ? 0 : pp;
  num = getFullNum(num);
  const n = (num + '').split('.')
  let x = n.length > 1 ? n[1] : ''
  if (x.length > p) {
    x = x.substr(0, p);
  } else {
    x += Array(p - x.length + 1).join('0');
  }
  return trimTrailingZeroes(n[0] + (x === '' ? '' : '.' + x));
}

export function getFullNum(num) {
  // 处理非数字
  if (isNaN(num)) {
    return num;
  }
  const str = String(num);
  if (!/e/i.test(str)) {
    return num;
  }
  return Number(num)
    .toFixed(18)
    .replace(/\.?0+$/, '');
}

export function accMul(arg1, arg2) {
  if (!parseFloat(arg1) || !parseFloat(arg2)) {
    return 0;
  }
  const num = new BigNumber(arg1).times(new BigNumber(arg2));
  return num.toFixed();
}

export function accDiv(arg1, arg2) {
  if (!parseFloat(arg1) || !parseFloat(arg2)) {
    return 0;
  }
  const num = new BigNumber(arg1).div(new BigNumber(arg2));
  return num.toFixed();
}

export function accAdd(arg1, arg2) {
  const num = new BigNumber(arg1).plus(new BigNumber(arg2));
  return num.toFixed();
}

export function accSub(arg1, arg2) {
  const num = new BigNumber(arg1).minus(new BigNumber(arg2));
  return num.toFixed();
}

// 大于等于
export function accGte(arg1, arg2) {
  return new BigNumber(arg1).gte(new BigNumber(arg2));
}

// 大于
export function accGt(arg1, arg2) {
  return new BigNumber(arg1).gt(new BigNumber(arg2));
}

export function gasProcessing(arg1) {
  return toFixed(accMul(new BigNumber(arg1), 1.1), 0);
}


export function toPrecision(num, significantDigits = 6) {
  if (!num) {
    return '0';
  }
  let m = new BigNumber(num);
  num = m.toPrecision(significantDigits, 1);
  if (num.includes('e')) {
    const [num1, uint] = num.split('e');
    const newUint = Number(uint);
    num = accMul(new BigNumber(num1).toString(10), new BigNumber(10).pow(newUint).toString(10));
  }
  return trimTrailingZeroes(num);
}
