// 定义状态管理相关数据
import Web3 from 'web3'
import settingConfig from '@/settings'

const state = {
  walletAddress: '', // 连接钱包的地址
  connectWalletType: '', // 连接钱包的模式 1 metamske、2 walletConnect
  isConnectWallet: false, // 是否连接钱包
  isBindSuperAddress: false,// 是否绑定了父级地址
  net: 0,
  block:0,
}

// 修改状态管理值
const mutations = {
  SET_WALLETADDRESS(state, value) {
    state.walletAddress = value;
  },
  SET_CONNECTWALLETTYPE(state, value) {
    state.connectWalletType = value;
  },
  SET_CONNECTWALLET(state, value) {
    state.isConnectWallet = value;
  },
  SETBLOCK: (state, block) => {
    state.block = block;
  },
  SETNET: (state, net) => {
    state.net = net;
  },
  SET_BINDSUPERADDRESS(state, value) {
    state.isBindSuperAddress = value;
  },
}

// 操作事件
const actions = {
  setWalletAddress({ commit }, value) {
    commit('SET_WALLETADDRESS', value);
  },
  setConnectWalletType({ commit }, value) {
    commit('SET_CONNECTWALLETTYPE', value);
  },
  setConnectWallet({ commit }, value) {
    commit('SET_CONNECTWALLET', value);
  },
  setBlock({ commit }, value) {
    commit('SETBLOCK', value);
  },
  setNet({ commit }, value) {
    commit('SETNET', value);
  },
  setBindSuperAddress({ commit }, value) {
    commit('SET_BINDSUPERADDRESS', value);
  },
  async setWallet() {
    let web3Provider;
    if (window.ethereum) {
      web3Provider = window.ethereum;
      try {
        await web3Provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: Web3.utils.toHex(process.env.VUE_APP_CHAIN_ID),
              chainName: 'Binance Smart Chain Mainnet',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'bnb',
                decimals: 18,
              },
              rpcUrls: [settingConfig.rpc],
              blockExplorerUrls: [`https://testnet.bscscan.com/`],
            },
          ],
        });
        await web3Provider.enable();
      } catch (error) {
        console.error('User denied account access');
      }
    }
  },
  async setWebProvider({ commit }) {
    let web3Provider;
    if (window.ethereum) {
      web3Provider = window.ethereum;
      try {
        // 请求用户授权
        await web3Provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: Web3.utils.toHex(process.env.VUE_APP_CHAIN_ID),
              chainName: 'Binance Smart Chain Mainnet',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'bnb',
                decimals: 18,
              },
              rpcUrls: [settingConfig.rpc],
              blockExplorerUrls: [`https://bscscan.com/`],
            },
          ],
        });
        await web3Provider.enable();
      } catch (error) {
        // 用户不授权时
        console.error('User denied account access');
      }
      const web3js = new Web3(web3Provider); //web3js就是你需要的web3实例
      commit('SETPROVIDER', web3js);
      web3js.eth.net.getId(function(error, result) {
        if (!error) {
          console.log(result, '网络'); //授权成功后result能正常获取到账号了
          commit('SETNET', result);
        }
      });
      web3js.eth.getAccounts(function(error, result) {
        if (!error) {
          console.log(result, '账号'); //授权成功后result能正常获取到账号了
          commit('SET_WALLETADDRESS', result[0]);
        }
      });
      web3Provider.on('networkChanged', function(networkIDString) {
        commit('SETNET', networkIDString);
      });
      web3Provider.on('accountsChanged', function(accounts) {
        commit('SET_WALLETADDRESS', accounts[0]);
        console.log('切换账号', accounts);
      });
    }
  },
}

export default {
  namespaced: true, // 限定在当前模块的命名空间中
  state,
  mutations,
  actions
}