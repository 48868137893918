export const routes = [
  {
    path: '/Ulab',
    name: 'Ulab',
    alias: '/',
    hidden: true,
    component: () => import('@/views/phone/defi/Ulab.vue'),
    meta: {
      title: 'Ulab',
      scrollToTop: true,
    },
  },

]