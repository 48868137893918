<template>
  <div class="footer">
    <div class="f_contain">

    </div>
  </div>
</template>

<script>
const settingConfig = require('../../settings.js');
export default {
  data() {
    return {
      isEnglish: false,
    }
  },
  // 初始创建 数据观测（data observer），属性和方法的运算，watch/event事件回调
  created() {
    this.isEnglish = localStorage.getItem("lang") === "en";
  },
  // 方法集合
  methods: {},
}
</script>

<style lang="scss" scoped>

.footer {
  height: 603px;
  padding-top: 0.1px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .f_contain {
    max-width: 1200px;
    margin: 32px auto 0;
    display: flex;
    justify-content: space-between;
  }
}

</style>