const zh = {
  message: {
    sidebar: {
      connect: '连接钱包',
      metafiText: 'MetaFi：探索  Meta',
      medPublicOffering: 'MED公募',
      defiText: 'A Big Cheese',
      defiText1: '首页',
      defiText2: 'Cheese NFT',
      defiText3: '€NFT挖矿',
      defiText4: '收益',
      defiText5: '冻结',
      defiText6: '推广',
      defiText7: '深海',
      defiText8: '交易',
      defiText9: '领取记录',
      defiText10: '配置地址',
      ComingSoon: '———  Coming Soon  ———',
      soonText1: '合约交易',
      soonText2: '交易挖矿',
      soonText3: 'API',
      buy: '买',
      pending: '待领取:',
      totalPower: '总算力',
      Destroy: '销毁',
      extractABC: '提取ABC',
      getDABC: '获得DABC',
      language: '语言',
      music: '音乐',
      emailContact: '邮件联系',
      guide: '指南',
      downloadFile: '下载文件',
    },
    nft: {
      nftTipsText: '€NFT是具有NFT矿机，具有为期40天的挖矿权限，质押€NFT矿机后可以获得ABC奖励。出售所得ABC全部进入黑洞销毁。',
      close: '关闭',
      nftText: '* 购买时ABC的数量可能与显示的数量不相等，这是由于ABC的价格浮动所造成的。在您购买时，将以金本位实时价格进行支付。',
      ApproveUSDT: '授权USDT',
      ApproveABC: '授权ABC',
      ApproveDABC: '授权DABC',
    },
    recommend: {
      myCaptain: '——— 我的推荐人 ———',
      associatedCaptainAddress: '关联推荐人',
      myCrew: '——— 我的推广 ———',
      crewAddress: '钱包地址',
      boardingTime: '推广时间',
      enterTheCaptainSAddress: '输入推荐人ID',
      confirmBinding: '确认绑定',
      Cancel: '取消',
    },
    mining: {
      valueInPledge: '质押中价值',
      cumulativeReleaseValue: '累计释放价值',
      pledging: '质押中',
      holding: '在持',
      toBeRetrieved: '待取回',
      releaseTimes: '释放次数:',
      deposit: '质押',
      approve: '授权',
      transfer: '转让',
      retrieveToWallet: '取回至钱包',
      enterReceivingAddress: '输入接收地址…',
    },
    profit: {
      TypeText0: '静态',
      TypeText1: '动态',
      TypeText2: '平级',
      TypeText3: '极差',
      TypeText4: '分红',
    },
    lang: {
      choose_language: '选择语言',
      chinese: '简体中文',
      english: '英文',
      japanese: '日文',
      korean: '韩文',
    },
    alertMsg: {
      MetaMaskPlugin: '请确保您的浏览器已安装 MetaMask 插件',
      alertMsg1: 'USDT授权成功！',
      alertMsg2: 'ABC授权成功！',
      alertMsg3: '购买成功！',
      alertMsg4: '余额不足！',
      alertMsg5: '没有可提取的ABC',
      alertMsg6: '转让成功！',
      alertMsg7: 'NFT授权成功！',
      alertMsg8: '质押成功！',
      alertMsg9: '请输入接收地址！',
      alertMsg10: '该地址未关联推荐人',
      alertMsg11: '绑定成功！',
      alertMsg12: '输入推荐人地址',
      alertMsg13: '操作取消！',
      alertMsg14: '输入为空！',
      alertMsg15: '复制成功！',
      alertMsg16: '复制失败！',
      alertMsg17: '取回成功！',
      alertMsg18: '提取成功！',
      alertMsg19: '请输入正确的接收地址！',
      alertMsg20: '敬请期待！',
      alertMsg21: 'DABC授权成功！',
      alertMsg22: 'UCASH授权成功！',
      alertMsg23: '没有可获取的DABC',
      alertMsg24: '最少提取100USDT',
    },
  }
}

export default zh