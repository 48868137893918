<template>
  <div id="app">
    <div class="header">
      <zoon-header @listenWalletConnect="handleMetamaskConnect"></zoon-header>
    </div>
    <div class="content">
      <router-view @listenWalletConnect="handleMetamaskConnect"></router-view>
    </div>
  </div>
</template>

<script>
import {isMobile} from './utils/utils.js';
import contractObject from '@/decentralizedApi/inits.js'
import Web3 from 'web3'
import {isStringEmpty} from '@/utils/validate'
import {formatAmount, toFixed} from '@/utils/format'

export default {
  data() {
    return {
      isPhone: false,
      isEnglish: false,
      languages: [this.$t('message.lang.chinese'), this.$t('message.lang.english'), this.$t('message.lang.japanese'), this.$t('message.lang.korean')]
    };
  },
  beforeCreate() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  async created() {
    // 判断是否存在小狐狸浏览器钱包
    if (typeof window.ethereum !== 'undefined') {
      // 当窗口存在window.ethereum对象时候立刻进行Web3初始化
      // 初始化合约
      this.init_web3_contractObject();
      this.getMetamaskConnectWallet();
      console.log('存在window.ethereum对象');
      // const blockNumber = await contractObject.getInitGlobalWeb3().eth.getBlockNumber();
      // await this.$store.dispatch('wallet/setBlock', blockNumber);
      // this.timer = setInterval(async () => {
      //   const blockNumber = await contractObject.getInitGlobalWeb3().eth.getBlockNumber();
      //   console.log('blockNumber', blockNumber);
      //   await this.$store.dispatch('wallet/setBlock', blockNumber);
      // }, 3000);
    }
    let that = this;
    this.$eventBus.$on('setWallet', function() {
      that.handleMetamaskConnect();
    })
  },
  async mounted() {
    // 国际化
    this.isEnglish = localStorage.getItem("lang") === "en";
    this.isPhone = isMobile();
  },
  computed: {
    connectWalletType: {
      get() {
        return this.$store.getters.connectWalletType;
      },
      set(val) {
        this.$store.dispatch('wallet/setConnectWalletType', val);
      }
    },
    isConnectWallet: {
      get() {
        return this.$store.getters.isConnectWallet;
      },
      set(val) {
        this.$store.dispatch('wallet/setConnectWallet', val);
      }
    },
    walletAddress: {
      get() {
        return this.$store.getters.walletAddress;
      },
      set(val) {
        this.$store.dispatch('wallet/setWalletAddress', val);
        if (val) {
          let userId = this.$md5(val).toUpperCase()
          localStorage.setItem("userId", userId)
        } else {
          localStorage.removeItem("userId")
        }
      }
    },
  },
  watch: {
    '$i18n.locale'(val, oval) {
      this.isEnglish = val !== "zh";
      this.languages = [this.$t('message.lang.chinese'), this.$t('message.lang.english'), this.$t('message.lang.japanese'), this.$t('message.lang.korean')];
    },
    // 监听是否授权连接钱包
    isConnectWallet(val, oval) {
      this.$store.dispatch('wallet/setConnectWallet', val);
      console.log('钱包已经', val ? '连接' : '断开');
    },
  },
  methods: {
    formatAmount, toFixed,
    // 查询浏览器连接小狐狸钱包信息
    getMetamaskConnectWallet() {
      const that = this;
      let connectTimeout = setTimeout(() => {
        const networkID = window.ethereum.networkVersion;
        let web3 = contractObject.getInitGlobalWeb3();
        console.log("window ethereum network id = ", networkID);
        if (networkID == process.env.VUE_APP_CHAIN_ID) {
          let address = window.ethereum.selectedAddress;
          address = isStringEmpty(address) ? window.ethereum.address : address
          if (!isStringEmpty(address)) {
            that.connectWalletType = '1';
            that.isConnectWallet = true;
            that.walletAddress = web3.utils.toChecksumAddress(address);
            that.monitorWalletSwitched();
          }
        } else {
          that.switchNetwork();
        }
        clearTimeout(connectTimeout);
      }, 500);
    },
    // 主动触发Metamask连接
    async handleMetamaskConnect() {
      let web3Provider;
      let that = this;

      if (!Web3.givenProvider) {
        this.$message({ message: this.$t("message.alertMsg.MetaMaskPlugin") });
        return;
      }

      if (typeof window.ethereum !== 'undefined') {
        web3Provider = window.ethereum;
      } else if (typeof window.web3 !== 'undefined') { // 老版 MetaMask Legacy dapp browsers...
        web3Provider = window.web3.currentProvider;
      }

      let web3 = contractObject.getInitGlobalWeb3();

      try {
        // 当窗口存在window.ethereum对象时候立刻进行Web3初始化
        this.init_web3_contractObject();
        const networkID = window.ethereum.networkVersion;
        console.log("window ethereum network id = ", networkID);
        if (networkID == process.env.VUE_APP_CHAIN_ID) {
          const accounts = await web3Provider.request({ method: 'eth_requestAccounts' });
          that.isConnectWallet = true;
          that.connectWalletType = '1';
          that.walletAddress = web3.utils.toChecksumAddress(accounts[0]);
          that.monitorWalletSwitched();
        } else {
          that.switchNetwork();
        }
      } catch (error) { // 用户不授权时
        console.log("error", error);
      }
    },
    // 小狐狸钱包监听用户是否切换钱包或者网络
    monitorWalletSwitched() {
      // this.init_web3_contractObject(new Web3(window.ethereum));
      let that = this;
      let web3 = contractObject.getInitGlobalWeb3();
      ethereum.on("chainChanged", async function(chainId) {
        const idNum = Web3.utils.hexToNumberString(chainId);
        if (idNum != process.env.VUE_APP_CHAIN_ID) {// 判断网络ID是否跟配置的网络ID相等
          that.walletAddress = '';
          that.connectWalletType = '';
          that.isConnectWallet = false;
          that.switchNetwork();
        } else { // 获取小狐狸当前登录钱包地址
          let address = window.ethereum.selectedAddress;
          address = isStringEmpty(address) ? window.ethereum.address : address
          if (!isStringEmpty(address)) {
            that.walletAddress = web3.utils.toChecksumAddress(address);
            that.connectWalletType = '1';
            that.isConnectWallet = true;
          }
        }
      });
      // 监听如果更改账户，则重新登录
      ethereum.on("accountsChanged", function(accounts) {
        if (!isStringEmpty(accounts[0])) {
          that.walletAddress = web3.utils.toChecksumAddress(accounts[0]);
          that.connectWalletType = '1';
          that.isConnectWallet = true;
        } else {
          that.walletAddress = '';
          that.connectWalletType = '';
          that.isConnectWallet = false;
        }
        console.log("账户发生修改,需要重新授权登录");
      });
      ethereum.on('disconnect', function(error) {
        that.walletAddress = '';
        that.connectWalletType = '';
        that.isConnectWallet = false;
        console.log("账户已经断开链接,需要重新授权登录");
      });
    },
    switchNetwork() {
      try {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(process.env.VUE_APP_CHAIN_ID) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          alert('add this chain id')
        }
      }
    },
    // 初始化合约对象并进行各种合约对象的初始化
    init_web3_contractObject() {
      contractObject.initWithContractObject();
    },

  },
}


</script>

<style lang="scss" scoped>
#app {
  display: flex;
  display: -webkit-flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  color: $white;
  background-color: $white;
  .header {
    flex: none;
  }
  .content {
    flex: 1 1 auto;
    max-height: 100%;
    overflow: auto;
  }
}

</style>
