import BigNumber from "bignumber.js";


/**
 *  弹框时候是否允许底层滑动
 */
export function setupBottomlayerScroll(isAllow) {
  if (isAllow) {
    document.documentElement.style.overflowY = 'auto';
  } else {
    document.documentElement.style.overflowY = 'hidden';
  }
}

/**
 * 判断当前设备是否是手机端
 */
export function isMobile() {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return flag;
}

/**
 *  字符串截取方法
 */
export function getCharactersLen(charStr, cutCount) {
  if (charStr == null || charStr == '') return '';
  var totalCount = 0;
  var newStr = '';
  for (var i = 0; i < charStr.length; i++) {
    var c = charStr.charCodeAt(i);
    if (c < 255 && c > 0) {
      totalCount++;
    } else {
      totalCount += 2;
    }
    if (totalCount >= cutCount) {
      newStr += charStr.charAt(i);
      break;
    } else {
      newStr += charStr.charAt(i);
    }
  }
  return newStr;
}

/**
 *  获取元素在数组中的下标
 */
export function getObjectIndexAtArray(arr, obj) {
  var i = arr.length;
  while (i--) {
    if (arr[i] === obj) {
      return i;
    }
  }
  return -1;
}

/**
 * 删除数组中某个对象
 */
export function removeObjectFromArray(_arr, _obj) {
  var length = _arr.length;
  for (var i = 0; i < length; i++) {
    if (JSON.stringify(_arr[i]) == JSON.stringify(_obj)) {
      if (i == 0) {
        _arr.shift(); //删除并返回数组的第一个元素
        return _arr;
      } else if (i == length - 1) {
        _arr.pop();  //删除并返回数组的最后一个元素
        return _arr;
      } else {
        _arr.splice(i, 1); //删除下标为i的元素
        return _arr;
      }
    }
  }
}

// 过度数据
export function overExtensibleListData(dataList) {
  var copiedData = [];
  dataList.forEach(obj => {
    var newObj = {};
    for (let i in obj) {
      newObj[i] = obj[i];
    }
    copiedData.push(newObj);
  });
  return copiedData;
}

export function overExtensibleMapData(object) {
  var newObj = {};
  for (let i in object) {
    newObj[i] = object[i];
  }
  return newObj;
}

//返回数组元素是否出现重复项（等于0：没有，大于0：有）
export function checkArrayElementRepeat(array) {
  array.sort();  //数组排序
  var reNum = 0;  //返回结果
  //遍历整个数组对象
  for (var i = 0; i < array.length; i++) {
    //跳过最后一个元素的比较
    if (i + 1 == array.length) {
      continue;
    }
    // 判断相邻的元素是否相同
    if (array[i] == array[i + 1]) {
      reNum += 1;
    }
  }
  return reNum > 0 ? true : false;
}

export function convertStringToHex(value) {
  return new BigNumber(`${value}`).toString(16);
}

export function sanitizeHex(hex) {
  hex = hex.substring(0, 2) === "0x" ? hex.substring(2) : hex;
  if (hex === "") {
    return "";
  }
  hex = hex.length % 2 !== 0 ? "0" + hex : hex;
  return "0x" + hex;
}


export function timestampToTime(timestamp, fmt = 'MM-dd hh:mm:ss') {
  let date = new Date(timestamp * 1000);
  return formatDate(date, fmt);
}

function formatDate(date = new Date(), fmt = 'yyyy-MM-dd hh:mm:ss') {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };

  // 遍历这个对象
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ('00' + str).substring(str.length);
}