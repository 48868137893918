<template>
  <div class="header">
    <div class="ulab-content" v-if="isUlab===1">
      <div class="ulab-content-left">
        <van-image :show-loading="false" :src="require('../../assets/img/icon_logo_ulab.png')" class="logo"/>
      </div>
      <div class="ulab-content-right" @click="handleWalletConnect">
        <div class="chian-text">BSC</div>
        <div class="address-text">{{ isConnectWallet ? omitCharacters(walletAddress, 7) : $t('message.sidebar.connect') }}</div>
      </div>
    </div>
    <div class="content" v-if="isUlab===2">
      <div class="content-left">
        <van-image :show-loading="false" :src="require('../../assets/img/icon_open_left.png')" class="open_left" @click="handleOpenLeft"/>
        <van-image :show-loading="false" :src="require('../../assets/img/icon_logo_text_white.png')" class="logo"/>
      </div>
    </div>
  </div>
</template>

<script>

import {isMobile} from '@/utils/utils';
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      isUlab: 0,
      isIphone: false,
      isEnglish: false,
    }
  },
  computed: {
    ...mapGetters(['isConnectWallet', 'walletAddress']),
  },
  created() {
    // 是否是手机
    this.isIphone = isMobile();
    // 国际化
    this.isEnglish = localStorage.getItem("lang") === "en";
  },
  // 监听方法
  watch: {
    // 监听路由变化
    $route(to, from) {
      this.isUlab = to.name === "Ulab" ? 1 : 2
    },
  },
  // 方法集合
  methods: {
    omitCharacters(address, reserve) {
      const newAddress = address.substring(0, 5) + '...' + address.substring(address.length - reserve);
      return newAddress;
    },
    handleOpenLeft() {
      this.$emit('listenOpen')
    },
    handleWalletConnect() {
      if (this.isConnectWallet)
        return
      this.$emit('listenWalletConnect')
    },
  },
}
</script>

<style lang="scss" scoped>

.header {
  width: 100vw;
  height: 90px;
  position: relative;
  user-select: none;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  .content {
    background-color: $black;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    align-items: center;
    .content-left {
      display: flex;
      align-items: center;
      .open_left {
        width: 40px;
        height: 40px;
      }
      .logo {
        margin-left: 30px;
        width: 180px;
        height: 51px;
      }
    }
    .content-right {
      display: flex;
      align-items: center;
      .tips {
        width: 60px;
        height: 60px;
      }
    }
  }
  .ulab-content {
    background-color: $white;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 30px;
    align-items: center;
    .ulab-content-left {
      .logo {
        width: 142px;
        height: 54px;
      }
    }
    .ulab-content-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 46px;
      padding: 0 10px;
      border-radius: 23px;
      background-color: #eaf0f6;
      .chian-text {
        width: 80px;
        height: 36px;
        border-radius: 18px;
        background-color: #fab32e;
        font-family: PingFangSC;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 36px;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
      .address-text {
        margin-left: 15px;
        font-family: PingFangSC;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #606060;
      }
    }
  }
}

</style>