import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import qs from 'qs'

import EventBus from './utils/bus.js'
import DecentralizedApi from './decentralizedApi/decentralizedApi.js'
import {i18n, vantLocales} from './i18n/i18n'

import zoonHeader from './components/zoonHeader/index'
import zoonFooter from './components/zoonFooter/index'

import Loading from './components/loading/index.js'
import DialogMsg from './components/dialogmsg/index.js'
import Message from '@/components/messageToast/index';
import Vant, {Lazyload} from 'vant';
import 'vant/lib/index.less';
import '@vant/touch-emulator';

import "./styles/index.scss"
import './fonts/myFont.css';
import 'normalize.css/normalize.css'; // 消除各浏览器差异
import VConsole from 'vconsole';
import VueClipboard from 'vue-clipboard2'
import md5 from 'js-md5';

Vue.prototype.$md5 = md5;

Vue.config.productionTip = false;
// 挂载到原型上当成属性调用
Vue.prototype.$QS = qs;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$decentralized = DecentralizedApi;

// 开发环境使用，生产环境自动取消
if (process.env.NODE_ENV === "development") {
  const vConsole = new VConsole({ theme: 'dark' });
}

Vue.use(VueClipboard)
// vant-ui组件国际化
Vue.use(Lazyload);
// 全局引入插件或者组件
Vue.use(zoonHeader);
Vue.use(zoonFooter);
Vue.use(DialogMsg);
Vue.use(Message);
Vue.use(Loading);
Vue.use(Vant);
vantLocales(i18n.locale)
/**
 vue.prototype 和 vue.use()的区别
 1、不是为了vue写的插件(插件内要处理)不支持Vue.use()加载方式
 2、非vue官方库不支持new Vue()方式
 3、每一个vue组件都是Vue的实例，所以组件内this可以拿到Vue.prototype上添加的属性和方法。
 4、Vue的插件是一个对象,插件对象必须有install字段.install字段是一个函数.初始化插件对象需要通过Vue.use()

 安装vue.js插件，如果插件是一个对象，必须提供install方法
 如果插件是一个函数，它会被作为install方法。install调用时，会将vue作为参数传入

 总结：组件是是Vue的实例，this可以拿到Vue.prototype上添加的属性和方法
 插件是一个对象,插件对象必须有install字段.install字段是一个函数.初始化插件对象需要通过Vue.use()
 */


// 创建和挂载根实例。(比如通过 router 配置参数注入路由、从而让整个应用都有路由功能）
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
