const settingConfig = require('../settings.js');

import Web3 from "web3";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

// 定义一个全局的Web3对象
let globalWeb3 = null
// 定义全局的WalletConnector对象
let globalWalletConnector = null

function initWithContractObject() {
  // 初始化对应合约
  const web3 = getInitGlobalWeb3();
}

// 获取web3对象
function getInitGlobalWeb3() {
  // if (globalWeb3 !== null) {
  //   return globalWeb3;
  // }
  if (typeof window.ethereum !== 'undefined') {
    globalWeb3 = new Web3(window.ethereum);
  } else {
    globalWeb3 = new Web3(Web3.givenProvider || new Web3.providers.HttpProvider(process.env.VUE_APP_RPC));
  }
  return globalWeb3;
}

// 当使用walletConnect连接钱包保存connector对象
function initGlobalWalletConnector(connector) {
  globalWalletConnector = connector;
}

// 获取connector对象
function getInitGlobalWalletConnector() {
  if (globalWalletConnector !== null) {
    return globalWalletConnector;
  }
  const connector = new WalletConnect({ bridge: settingConfig.walletConnectBridge, qrcodeModal: QRCodeModal });
  globalWalletConnector = connector;
  return globalWalletConnector;
}

export default {
  initWithContractObject, getInitGlobalWeb3, initGlobalWalletConnector, getInitGlobalWalletConnector
};







