/**
 * 设置模块下定义变量
 * 不同环境状态下定义不同的变量状态
 */
module.exports = {
  // 应用名称
  name: 'ULAB',
  // 测试服务器版本号
  devVersion: '1.0.0',
  // 正式服务器版本号
  disVersion: '1.0.0',
  // 服务器接口地址
  baseOneURL: 'https://dapp.abcheese.club/api/abc/v1/', // http://192.168.1.19:8002/
  baseTwoURL: 'https://api.u-lab.club/ulab/v1/', // http://192.168.1.19:8002/
  imgURL: 'https://image.abcheese.club/',
  // rpc 节点地址
  rpc: 'https://bsc-dataseed.binance.org/',
  // wallet Connect
  walletConnectBridge: 'https://bridge.walletconnect.org',
  // telegram
  telegramUrl: '',
  // medium
  mediumUrl: '',
  // twitter
  twitterUrl: '',
  //email
  emailUrl: '',
  // guide
  guideUrl: '',
  // 购买cabo币
  buyCaboUrl: 'https://pancakeswap.finance/swap?outputCurrency=',
  // 置换流动性LP的地址
}