<template>
  <div v-show="visible" class="m-message">
    <div class="toast-box">
      <span class="toast-message">{{ content }}</span>
    </div>
  </div>
</template>

<script>
import {viewHash} from '@/utils/common';

export default {
  name: 'message',
  props: {
    iconImg: String,
    closable: Boolean,
    closeHandler: Function,
    title: String,
    content: String,
    supportHTML: Boolean, // content support html
    isCollapsed: {
      type: Boolean,
      default: true,
    },
    collapsable: {
      type: Boolean,
      default: true,
    },
    hash: String,
  },
  data() {
    return {
      visible: true,
      collapsed: this.isCollapsed,
    };
  },
  methods: {
    viewHash,
    triggerCollapse() {
      this.collapsed = !this.collapsed;
    },
    close() {
      this.visible = false;
    },
    handleClose() {
      if (typeof this.closeHandler === 'function') this.closeHandler(this.close);
      else this.close();
    },
  },
};
</script>

<style lang="less" scoped>
.toast-box {
  width: 690px;
  padding: 20px 60px;
  opacity: 0.8;
  border: 2px solid transparent;
  border-radius: 10px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #272727, #272727), linear-gradient(79deg, #ef9d1a -1%, #ce441a 100%);;
  display: flex;
  justify-content: center;
  .toast-message {
    font-family: PingFangSC;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffc917;
  }
}


</style>
