import Vue from 'vue'
import VueI18n from "vue-i18n";
import {Locale} from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import enLocale from './lang/en'
import zhLocale from './lang/zh'

Vue.use(VueI18n);

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  // ja: {
  //   ...jaJP,
  //   ...jaLocale
  // },
  // ko: {
  //   ...koKO,
  //   ...koLocale
  // }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'zh',// 设置国际化地区
  messages, // 设置地区相关信息内容数据
  silentTranslationWarn: true,//去掉国际化警告
})

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  }
  // else if (lang === 'ja') {
  //   Locale.use(lang, jaJP)
  // } else if (lang === 'ko') {
  //   Locale.use(lang, koKO)
  // }
}

export {i18n, vantLocales}